import Places from "../components/Places"

const PlacesPage = () => {
  return (
    <>
      <Places />
    </>
  );
};

export default PlacesPage;