import LeafletMapComponent from "../components/LeafletMapComponent";

const MapPage = () => {
  return (
    <>
      <LeafletMapComponent />
    </>
  );
};

export default MapPage;