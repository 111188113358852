import { useState, useEffect } from "react";
import { Form, FloatingLabel } from 'react-bootstrap';
import { getAllCountries } from "../api/PlacesApi.js";

function Settings() {
  const maxPlacesOptions = [
    { value: 10, label: "max. 10 Plätze" },
    { value: 25, label: "max. 25 Plätze" },
    { value: 50, label: "max. 50 Plätze" },
    { value: 100, label: "max. 100 Plätze" },
    { value: 250, label: "max. 250 Plätze" },
    { value: 500, label: "max. 500 Plätze" },
    { value: 1000, label: "max. 1000 Plätze" },
    { value: 2500, label: "max. 2500 Plätze" },
    { value: 5000, label: "noch mehr Plätze" },
  ];
  maxPlacesOptions.forEach(r => {
    r.key = r.value;
  });

  const initialCountryOptions = [
    { value: undefined, label: "alle Länder" },
    { value: "Deutschland", label: "Deutschland" },
    { value: "Frankreich", label: "Frankreich" },
    { value: "Portugal", label: "Portugal" },
  ];
  initialCountryOptions.forEach(r => {
    r.key = r.value !== undefined ? r.value : r.label;
  });

  const maxPriceOptions = [
    { value: 0, label: "kostenlos" },
    { value: 1, label: "bis € 1,-" },
    { value: 5, label: "bis € 5,-" },
    { value: 10, label: "bis € 10,-" },
    { value: 20, label: "bis € 20,-" },
    { value: 30, label: "bis € 30,-" },
    { value: -1, label: "beliebig" },
  ];
  maxPriceOptions.forEach(r => {
    r.key = r.value !== undefined ? r.value : r.label;
  });

  const storedMaxPlaceCount = localStorage.getItem("maxPlaceCount");
  const [maxPlaceCount, setMaxPlaceCount] = useState(storedMaxPlaceCount ? storedMaxPlaceCount : maxPlacesOptions[2].value);
  const [countryOptions, setCountryOptions] = useState(initialCountryOptions[0].value);
  const storedCountry = localStorage.getItem("country");
  const [country, setCountry] = useState(storedCountry ? storedCountry : initialCountryOptions[0].value);
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice, setMaxPrice] = useState(storedMaxPrice ? storedMaxPrice : maxPriceOptions[maxPriceOptions.length - 1].value);

  useEffect(() => {
    localStorage.setItem("maxPlaceCount", maxPlaceCount);
    localStorage.setItem("country", country);
    localStorage.setItem("maxPrice", maxPrice);

    const fetchAllCountries = async () => {
      if (countryOptions && countryOptions.length > 5) {
        return;
      }
      const res = await getAllCountries();
      if (res && res.length > 0) {
        const newOptions = res.map((r) => ({ key: r.Land, value: r.Land, label: r.Land }));
        setCountryOptions(newOptions);
      }
    };

    fetchAllCountries();
  }, [maxPlaceCount, country, maxPrice, countryOptions]);

  return (
    <>
      <FloatingLabel className="mb-3" controlId="floatingSelect" label="Max. angezeigte Plätze">
        <Form.Select
          aria-label="Max. angezeigte Plätze" // ?????
          key="select_maxPlaceCount"
          value={maxPlaceCount}
          onChange={e => setMaxPlaceCount(e.target.value)}
        >
          {maxPlacesOptions && maxPlacesOptions.map(o => (
            <option key={o.value} value={o.value}>{o.label}</option>
          ))}
        </Form.Select>
      </FloatingLabel>

      <FloatingLabel className="mb-3" controlId="floatingSelect" label="Land/Nation">
        <Form.Select
          key="select_country"
          value={country}
          onChange={e => setCountry(e.target.value)}
        >
          <option select="true" key="alle Länder" value="">alle Länder</option>
          {countryOptions && countryOptions.map(o => (
            <option key={o.value} value={o.value}>{o.label}</option>
          ))}
        </Form.Select>
      </FloatingLabel>

      <FloatingLabel className="mb-3" controlId="floatingSelect" label="Maximaler Preis">
        <Form.Select
          key="select_maxPrice"
          value={maxPrice}
          onChange={e => setMaxPrice(e.target.value)}
        >
          {maxPriceOptions && maxPriceOptions.map(o => (
            <option key={o.key} value={o.value} >{o.label}</option>
          ))}
        </Form.Select>
      </FloatingLabel>
    </>
  );
}

export default Settings;