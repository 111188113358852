import { React, useEffect, useState } from "react"
import { MapContainer, TileLayer, Marker, Popup, Tooltip, useMapEvent } from 'react-leaflet'
import { Icon, LatLng, LatLngBounds } from "leaflet";
import Place from "./Place";
import { getPlaces } from "../api/PlacesApi";

var loadingNeeded = true;

function LeafletMapComponent() {
  const [places, setPlaces] = useState([]);
  const [, setActivePlace] = useState(null);
  const storedCurrentBounds = localStorage.getItem("currentBounds");
  const [currentBounds, setCurrentBounds] = useState((storedCurrentBounds && _isJSONString(storedCurrentBounds)) ? JSON.parse(storedCurrentBounds) : { "west": -3, "east": 28, "north": 53, "south": 47 });
  const storedMaxPlaceCount = localStorage.getItem("maxPlaceCount");
  const [maxPlaceCount] = useState(storedMaxPlaceCount ? storedMaxPlaceCount : 333);
  const storedMaxPrice = localStorage.getItem("maxPrice");
  const [maxPrice] = useState(storedMaxPrice ? storedMaxPrice : -1);
  const storedCountry = localStorage.getItem("country");
  const [country] = useState(storedCountry ? storedCountry : undefined);

  function createTooltip(place) {
    const preis = place.Preis;
    let ret;
    if (preis === undefined || preis === null) {
      ret = place.Name;
    } else {
      ret = place.Name + " (" + place.Preis.toFixed(2) + " €)";
    }
    return ret;
  }

  function createIcon(place) {
    const preis = place.Preis;
    let imageName = "";
    if (preis === undefined || preis === null) {
      imageName = "gray";
    } else if (preis > 30) {
      imageName = "pink";
    } else if (preis > 20) {
      imageName = "red";
    } else if (preis > 10) {
      imageName = "orange";
    } else if (preis > 0) {
      imageName = "yellow";
    } else if (preis === 0) {
      imageName = "green";
    }
    const iconUrl = "/pins/" + imageName + ".svg";
    const ret = new Icon({
      iconUrl: iconUrl,
      iconSize: [38, 95],
      iconAnchor: [19, 65],
      popupAnchor: [0, -40],
    });
    return ret;
  }

  function getBounds() {
    const corner1 = new LatLng(currentBounds.north, currentBounds.east);
    const corner2 = new LatLng(currentBounds.south, currentBounds.west);
    const bounds = new LatLngBounds(corner1, corner2);
    return bounds;
  }

  function MapEventHandlers() {
    useMapEvent('moveend', e => {
      if (e && e.sourceTarget) {
        const movedBounds = e.sourceTarget.getBounds();
        const newBounds = { "west": movedBounds.getWest(), "east": movedBounds.getEast(), "north": movedBounds.getNorth(), "south": movedBounds.getSouth() };
        loadingNeeded = true;
        setCurrentBounds(newBounds);
        const stringified = JSON.stringify(newBounds);
        localStorage.setItem("currentBounds", stringified);
      }
    })
  }

  useEffect(() => {
    const fetchPlaces = async () => {
      if (loadingNeeded === false && places.length > 0) {
        return;
      }
      const props = { onlyWithLocation: true, bounds: currentBounds, maxSize: Math.min(1000, maxPlaceCount), maxPrice: maxPrice, country: country };
      const res = await getPlaces(props);
      if (res && res.length > 0) {
        setPlaces(res);
        loadingNeeded = false;
      }
    };
    fetchPlaces();
  }, [places, currentBounds, maxPlaceCount, maxPrice, country]);

  return (
    <MapContainer scrollWheelZoom={false} bounds={getBounds()}>
      <MapEventHandlers />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {places.map(p => (
        <Marker
          key={p.Id}
          position={[
            p.Latitude,
            p.Longitude
          ]}
          onClick={() => {
            setActivePlace(p);
          }}
          icon={createIcon(p)}
        >
          <Tooltip>{createTooltip(p)}</Tooltip>
          <Popup>
            <Place key={p.Id} place={p} />
          </Popup>

        </Marker>
      ))}
    </MapContainer>
  )
}

function _isJSONString(string) {
  try {
    const parsed = JSON.parse(string)
    if (parsed && typeof parsed === "object") {
      return true
    }
  } catch { return false }
  return false
}

export default LeafletMapComponent;