
export const StatisticsTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p><strong>{new Date(label).toLocaleDateString('de-DE') + ", " + new Date(label).getHours() + ":" + new Date(label).getMinutes().toString().padStart(2, '0') + " Uhr"}</strong></p>
        {payload.map(p => (<p key={JSON.stringify(p)}>{p.name}: {p.value.toLocaleString('de-DE')}</p>))}
      </div>
    );
  }
};


export default StatisticsTooltip;