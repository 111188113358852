import { PureComponent, React, useEffect, useState } from 'react';
import { ComposedChart, Area, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { StatisticsTooltip } from './StatisticsTooltip';
import { getStatistics } from "../api/PlacesApi.js";

function HeartbeatStatistics(props) {
  const [statistics, setStatistics] = useState([]);

  class CustomizedAxisTick extends PureComponent {
    render() {
      const { x, y, payload } = this.props;

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-60)">
            {new Date(payload.value).toLocaleDateString('de-DE')}
          </text>
        </g>
      );
    }
  }

  useEffect(() => {
    const fetchStatistics = async () => {
      if (statistics && statistics.length > 0) {
        return;
      }
      const res = await getStatistics();
      if (res && res.length > 0) {
        const newData = res.map(r => mapIt(r));
        setStatistics(newData);
      }
    };

    fetchStatistics();
  }, [statistics]);

  return (
    <div style={{ width: '100%', height: 800 }}>
      <ResponsiveContainer>
        <ComposedChart
          width={500}
          height={400}
          data={statistics}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="dateToNumber" tick={<CustomizedAxisTick />} height={100} type="number" scale="time" domain={['dataMin', 'dataMax']} />
          <YAxis dataKey={(v)=>parseInt(v.placesDiffRel)} unit=" pro Minute" type="number" domain={[0, 'maxData']} />
          <Tooltip content={<StatisticsTooltip />} />
          {/* <Tooltip formatter={(value, name, props) => Math.round(value*100)/100}/> */}
          <Legend verticalAlign="middle" layout='vertical' height={80} />
          <Area type="stepBefore" dataKey="placesDiffRel" stroke="#82ca9d" fill="#82ca9d" fillOpacity={.2} />
          <Area type="stepBefore" dataKey="scrapeLinksDiffRel" stroke="#8884d8" fill="#8884d8" fillOpacity={.2} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

function mapIt(r) {
  const data = JSON.parse(r.Data);
  const dataPlus = JSON.parse(r.DataPlus);
  return {
    date: r.Date,
    dateToNumber: (new Date(r.Date)).getTime(),
    places: data.places,
    scrapeLinks: data.scrapeLinks,
    placesWithoutLand: data.placesWithoutLand,
    scrapeLinksWithData: data.scrapeLinksWithData,
    scrapeLinksWithoutData: data.scrapeLinksWithoutData,
    placesDiff: dataPlus.placesDiff,
    placesDiffRel: dataPlus.placesDiffRel,
    scrapeLinksDiff: dataPlus.scrapeLinksDiff,
    scrapeLinksDiffRel: dataPlus.scrapeLinksDiffRel,
  };
}


export default HeartbeatStatistics;
