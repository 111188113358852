import axios from "axios";

export const api = axios.create({
    baseURL: 'https://placesdata.photobrandy.de/api.php/records',
});

export const getPlaces = async (props) => {
    let text = "/Places?join=ScrapeLinks";
    if (props) {
        if (props.searchString && props.searchString.length > 0) {
            text = text + "&filter=Name,cs," + props.searchString;
        }
        if (props.country && !(props.country === undefined) && !(props.country === 'undefined')) {
            text = text + "&filter=Land,eq," + props.country;
        }
        if (props.maxPrice && !(props.maxPrice === undefined) && props.maxPrice >= 0) {
            text = text + "&filter=Preis,le," + props.maxPrice;
        }
        if (props.maxSize) {
            text = text + "&size=" + props.maxSize;
        }
        if (props.onlyWithLocation && !(props.onlyWithLocation === undefined) && (props.onlyWithLocation === true)) {
            text = text + "&filter=Latitude,nis" + "&filter=Longitude,nis";
        }
        if (props.bounds) {
            const bounds = props.bounds;
            text = text + "&filter=Latitude,bt," + bounds.south + "," + bounds.north + "&filter=Longitude,bt, " + bounds.west + ", " + bounds.east;
        }
        text = text + "&order=updated_at,desc";
    }
    const response = await api.get(text);
    return response.data.records;
};

export const getAllCountries = async () => {
    let text = "/Places?include=Land";
    const response = await api.get(text);
    const all = response.data.records;
    const countries = [];
    all.forEach(el => {
        if (el.Land && !(countries.includes(el.Land))) {
            countries.push(el.Land);
        }
    });
    countries.sort(function (w1, w2) {
        return w1.localeCompare(w2);
    });
    const ret = [];
    countries.forEach(c => {
        ret.push({ key: c, Land: c });
    });

    return ret;
};

export const getStatistics = async (props) => {
    let statisticsCache = JSON.parse(sessionStorage.getItem("statisticsCache"));
    if (statisticsCache) {
        const cacheDate = statisticsCache.cacheDate;
        const cache = statisticsCache.cache;
        const diff = new Date() - (new Date(cacheDate));
        if (cache && cacheDate && diff < (1000)) {
            return cache;
        }
    }
    let text = "/Statistics?order=Date,asc";
    const response = await api.get(text);
    let records = response.data.records;
    records = addElementForNow(records);
    const ret = addHeartbeat(records);
    statisticsCache = { cache: ret, cacheDate: new Date() };
    sessionStorage.setItem("statisticsCache", JSON.stringify(statisticsCache));
    return ret;
};

function addElementForNow(records) {
    const lastElement = records.pop();
    const newElement = { Date: new Date().toISOString().slice(0, 19).replace('T', ' '), Data: lastElement.Data };
    records.push(lastElement);
    records.push(newElement);
    return records;
}

function addHeartbeat(res) {
    const parsed = res; // JSON.parse(res);
    for (let index = 0; index < parsed.length; index++) {
        const element = parsed[index];
        if (index < 1) {
            const data = JSON.parse(element.Data);
            data.placesDiff = 0;
            data.scrapeLinksDiff = 0;
            element.DataPlus = JSON.stringify(data);
        }
        else {
            const date = new Date(element.Date);
            const preDate = new Date(res[index - 1].Date);
            const dateDiff = (date - preDate) / 1000 / 60; // in minutes
            const data = JSON.parse(element.Data);
            const preData = JSON.parse(res[index - 1].Data);
            data.placesDiff = data.places - preData.places;
            data.placesDiffRel = (data.places - preData.places) / dateDiff;
            data.scrapeLinksDiff = data.scrapeLinks - preData.scrapeLinks;
            data.scrapeLinksDiffRel = (data.scrapeLinks - preData.scrapeLinks) / dateDiff;
            element.DataPlus = JSON.stringify(data);
        }
    }
    return res; // JSON.stringify(parsed);
}
