import HeartbeatStatistics from "../components/HeartbeatStatistics";
import PlacesStatistics from "../components/PlacesStatistics"
import ScrapeLinksStatistics from "../components/ScrapeLinksStatistics"

const StatisticsPage = () => {
  return (
    <>
      <PlacesStatistics />
      <ScrapeLinksStatistics />
      <HeartbeatStatistics />
    </>
  );
};

export default StatisticsPage;