import Settings from "../components/Settings";

const SettingsPage = () => {
  return (
    <>
    <h1>Settings</h1>
      <Settings />
    </>
  );
  };
  
  export default SettingsPage;