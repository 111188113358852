import ScrapeLink from "./ScrapeLink";

function Place(props) {
  const lat = props.place.Latitude;
  const lon = props.place.Longitude;
  let navigate = "";
  if (lat && lon) {
    const coordinates = lat + ", " + lon;
    const tooltipText = "Koordinaten: " + coordinates;
    const href = "https://www.google.com/maps/search/?api=1&query=" + lat + "%2C" + lon;
    navigate = <a className="btn btn-outline-primary" href={href} target="_blank" rel="noopener noreferrer" data-bs-toggle="tooltip" title={tooltipText}>
      in Google Maps anzeigen  
      {/* <span class="badge bg-primary rounded-pill">{coordinates}</span> */}
    </a>
  }
  
  return (
    <div className="card bg-light text-dark flex-fill flex-grow-1 p-1 m-1">
      <h5 className="card-header" >
        {props.place.Name}
      </h5>
      <div className="card-body" >
        <p>
          {props.place.PreisOriginal}
        </p>
        <p>
          {props.place.Land}
        </p>
        {navigate}
      </div>
      <div className="d-flex flex-wrap card-footer">
        {props.place.ScrapeLinks.map((sl) => <ScrapeLink key={sl.Id} scrapeLink={sl} />)}
      </div>
    </div>
  );
  // return <li>I am a Place</li>;
}

export default Place;