import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <div className="container-fluid">
      <nav className="container-fluid mt-4 p-2 bg-primary text-white rounded">
        <Link className="text-white" to="/">Home</Link> | <Link className="text-white" to="/places">Plätze</Link> | <Link className="text-white" to="/map">Karte</Link> | <Link className="text-white" to="/settings">Settings</Link> | <Link className="text-white" to="/statistics">Statistik</Link>
      </nav>
      <Outlet />
    </div>
  )
};

export default Layout;