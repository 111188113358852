function ScrapeLink(props) {
  const url = props.scrapeLink.Url;
  const tArray = url.split("//");
  let t = tArray[1];
  t = t.split("/")[0];
  const tooltipText = url;
  return (
    <div className="flex-fill">
      <a className="btn btn-outline-primary" href={url} target="_blank" rel="noopener noreferrer" data-bs-toggle="tooltip" title={tooltipText}>Direkt zu {t}</a>
    </div>
  );
  // return <li>I am a Place</li>;
}

export default ScrapeLink;